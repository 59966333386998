<template>
  <div>
        <fieldset class="p-2 mt-3 border">
            <legend class="w-auto">Zugewiesene Artikel</legend>
            <div class="row mb-3">
                <div class="col-md-12 text-right">
                    <button type="button" class="btn btn-primary btn-sm" @click="createModal">
                        <i class="fas fa-save"></i> Artikel hinzufügen
                    </button>
                </div>
            </div>
            <b-row>
                <b-col>
                    <b-table  striped hover outlined :fields="fields" :items="articles" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                        <template #cell(actions)="row">
                            <b-button size="sm" @click="removeArticle(row.item.id)" class="mr-1" variant="danger" v-if="$auth.check('articles.edit')"><i class="fas fa-trash" ></i></b-button>
                        </template>
                    </b-table>    
                </b-col>
            </b-row>
        </fieldset>

        <b-modal id="articleModal" title="Ähnliche Artikel verwalten" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk">
            <form ref="valueForm" @submit.stop.prevent="handleSubmit">
                <b-row class="mb-3" >
                    <b-col md="3">
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control" placeholder="Suchen" @keyup.enter="loadAvailableArticles" v-model="search" />
                            <button type="submit" class="btn btn-default" @click.prevent="loadAvailableArticles">
                                <i class="fas fa-search"></i>
                            </button>
                        </div>
                    </b-col>
                    <!-- <b-col md="3" offset-md="6" class="text-right">
                        <b-button size="sm" @click="addArticles" class="mr-1" variant="primary"><i class="fas fa-save" v-if="$auth.check('articles.edit')"></i> Artikel speichern</b-button>
                    </b-col> -->
                </b-row>
            
            <b-row v-if="availableArticles.data.length > 0">
                <b-col>
                    <!-- <b-table striped hover outlined :fields="articleFields" :items="availableArticles" :filter="filter" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                        <template v-slot:cell(selected)="row">
                            <b-form-group>
                                <input type="checkbox" v-model="form.articles" :value="row.item.id">
                            </b-form-group>
                        </template>
                    </b-table>
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination> -->
                    <table class="table table-striped">
                                    <thead>
                                        <tr data-widget="expandable-table" aria-expanded="false">
                                            <th>
                                                Sel
                                            </th>
                                            <th>
                                                <a href="#" @click.prevent="changeSort('ordernumber')">Artikelnummer</a>
                                                <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                                <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                                            </th>
                                            <th>
                                                <a href="#" @click.prevent="changeSort('name')">Artikelname</a>
                                                <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                                <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'desc'" >&darr;</span>
                                            </th>
                                            <th><a href="#" @click.prevent="changeSort('producer')">Hersteller</a>
                                                <span v-if="this.params.sort_field == 'producer' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                                <span v-if="this.params.sort_field == 'producer' && this.params.sort_direction == 'desc'" >&darr;</span></th>
                                            <th>
                                                <a href="#" @click.prevent="changeSort('price')">Preis</a>
                                                <span v-if="this.params.sort_field == 'price' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                                <span v-if="this.params.sort_field == 'price' && this.params.sort_direction == 'desc'" >&darr;</span>
                                            </th>
                                            <th>
                                                <a href="#" @click.prevent="changeSort('tax')">Steuersatz</a>
                                                <span v-if="this.params.sort_field == 'tax' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                                <span v-if="this.params.sort_field == 'tax' && this.params.sort_direction == 'desc'" >&darr;</span>
                                            </th>
                                            
                                        </tr>
                                        <tr class="expandable-body">
                                            <th>&nbsp;</th>
                                            <th>&nbsp;</th>
                                            <th>
                                                <select v-model="params.producer_id" class="form-control form-control-sm">
                                                    <option :value="''">-- Filter Hersteller --</option>
                                                    <option v-for="producer in producers" :key="producer.id" :value="producer.id">{{ producer.name }}</option>
                                                </select>
                                            </th>
                                            <th>&nbsp;</th>
                                            <th>
                                                <select v-model="params.tax_id" class="form-control form-control-sm">
                                                    <option :value="''">-- Filter Steuer --</option>
                                                    <option v-for="tax in taxes" :key="tax.id" :value="tax.id">{{ tax.description }}</option>
                                                </select></th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="article in availableArticles.data" :key="article.id">
                                            <td><input type="checkbox" v-model="form.articles" :value="article.id" /></td>
                                            <td>{{ article.ordernumber }}</td>
                                            <td>{{ article.name }}</td>
                                            <td>{{ article.producer }}</td>
                                            <td>{{ article.price | toCurrency}}</td>
                                            <td>{{ article.tax }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                </b-col>
            </b-row>

            <b-row v-if="availableArticles.data.length > 0">
                <pagination class="float-left" :data="availableArticles" @pagination-change-page="loadAvailableArticles" :limit="3"></pagination>
            </b-row>
            
            <b-row v-if="availableArticles.data.length == 0">
                <b-col>
                    <h4>Es konnten keine Artikel gefunden werden.</h4>
                </b-col>
            </b-row>
            </form>
        </b-modal>
  </div>
</template>

<script>
export default {
    name: "similarArticles",
    props: [
        'articles',
    ],
    data() {
        return {
            form: new window.Form({
                articles: [],
                article_id: "",
            }),
            sortBy: "id",
            sortDesc: false,
            perPage: 8,
            currentPage: 1,
            filter: "",
            fields: [
                {key: "ordernumber", label: "Artikelnummer", sortable: true},
                {key: "name", label: "Name", sortable: true},
                {key: "actions", label: "Actions", tdClass: 'hls_action_column'},
            ],
            articleFields:[
                {key: "selected", label: "Select", sortable: false},
                {key: "details.ordernumber", label: "Artikelnummer", sortable: true},
                {key: "name", label: "Name", sortable: true},
            ],
            producers: {},
            taxes: {},
            availableArticles: {
                data: [],
            },
            params: {
                sort_field: 'ordernumber',
                sort_direction: 'asc',
                producer_id: '',
                tax_id: '',
                per_page: 25,
                category_id: '',
            },
            search: '',
        }
    },

    watch: {
        params: {
            handler () {
                this.loadAvailableArticles();
            },
            deep: true
        },
    },

    methods:{
        getProducers() {
            this.$Progress.start();
            this.axios
                .get("/producers")
                .then((response) => {
                    this.producers = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getTaxes() {
            this.$Progress.start();
            this.axios
                .get("/taxes")
                .then((response) => {
                    this.taxes = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        handleOk(bvModalEvt){
            bvModalEvt.preventDefault();
            this.handleSubmit();
        },

        handleSubmit(){
            this.addArticles();
        },

        createModal(){
            this.$bvModal.show("articleModal");
            this.availableArticles = [];
            this.loadAvailableArticles();
        },

        removeArticle(id){
            this.$swal({
                title: "Möchtest du den Cross-Selling Artikel wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.form
                        .delete("/articles/" + this.$route.params.id+ "/removeSimiliar/" + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Artikel erfolgreich gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.$parent.loadArticle();
                        })
                        .catch((error) => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: error.response.data.error,
                            });
                        });
                }
            });
        },

        addArticles(){
            this.$Progress.start();
            this.form.article_id = this.$route.params.id;
            this.form
                .post("/articles/addSimiliar")
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Crosseling Artikel wurden gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$bvModal.hide("articleModal");
                    this.$Progress.finish();
                    this.$parent.loadArticle();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadAvailableArticles(page = 1){
            this.$Progress.start();
            this.availableArticles = [];
            //this.uploadImageForm.article_id = this.article.id;
            this.axios
                //.post("/articles/similiar", {article_id: this.$route.params.id, articles: this.articles})
                .get("/articles", {
                    params: {
                        page,
                        search: this.search.length >= 4 ? this.search : '',
                        ...this.params
                    }
                })
                .then((response) => {
                    this.availableArticles = response.data;
                    this.$Progress.finish();
                    //this.loadArticle();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }
    },

    created(){
        this.getTaxes();
        this.getProducers();
    }


}
</script>

<style>

</style>